@import '~antd/lib/style/themes/index';
@import '~antd/lib/style/mixins/index';


@keyframes loadingCircle {
  100% {
    transform: rotate(360deg);
  }
}

[ant-click-animating='true'],
[ant-click-animating-without-extra-node='true'] {
  position: relative;
}

.anticon-spin::before {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}

.anticon-spin {
  display: inline-block;
  -webkit-animation: loadingCircle 1s infinite linear;
  animation: loadingCircle 1s infinite linear;
}
